import React from "react"
import classNames from "classnames"

import Fancybox from "../../../../../components/_V3/Fancybox"

import PromptIconOne from "./assets/PromptIconOne.png"
import PromptIconTwo from "./assets/PromptIconTwo.png"
import PromptIconThree from "./assets/PromptIconThree.png"
import PromptIconFour from "./assets/PromptIconFour.png"
import PromptIconFive from "./assets/PromptIconFive.png"
import PromptIconSix from "./assets/PromptIconSix.png"
import PromptIconSeven from "./assets/PromptIconSeven.png"
import PromptIconEight from "./assets/PromptIconEight.png"
import PromptIconNine from "./assets/PromptIconNine.png"
import PromptIconTen from "./assets/PromptIconTen.png"
import PromptIconEleven from "./assets/PromptIconEleven.png"
import PromptIconTwelve from "./assets/PromptIconTwelve.png"
import PromptIconThreeTeen from "./assets/PromptIconThreeTeen.png"
import PromptIconFourTeen from "./assets/PromptIconFourTeen.png"

import MediaContentOne from "./assets/MediaContentOne.webp"
import MediaContentTwo from "./assets/MediaContentTwo.webp"
import MediaContentThree from "./assets/MediaContentThree.webp"
import MediaContentFour from "./assets/MediaContentFour.webp"
import MediaContentFive from "./assets/MediaContentFive.webp"
import MediaContentSix from "./assets/MediaContentSix.webp"
import MediaContentSeven from "./assets/MediaContentSeven.webp"
import MediaContentEight from "./assets/MediaContentEight.webp"
import MediaContentNine from "./assets/MediaContentNine.webp"
import MediaContentTen from "./assets/MediaContentTen.webp"
import MediaContentEleven from "./assets/MediaContentEleven.webp"
import MediaContentTwelve from "./assets/MediaContentTwelve.webp"
import MediaContentThirtTeen from "./assets/MediaContentThirtTeen.webp"
import MediaContentFourTeen from "./assets/MediaContentFourteen.webp"
import MediaContentFiveTeen from "./assets/MediaContentFiftTeen.webp"
import MediaContentSixTeen from "./assets/MediaContentSixTeen.webp"

import FancyMediaContentOne from "./assets/FancyMediaContentOne.webp"
import FancyMediaContentTwo from "./assets/FancyMediaContentTwo.webp"
import FancyMediaContentThree from "./assets/FancyMediaContentThree.webp"
import FancyMediaContentFour from "./assets/FancyMediaContentFour.webp"
import FancyMediaContentFive from "./assets/FancyMediaContentFive.webp"
import FancyMediaContentSix from "./assets/FancyMediaContentSix.webp"
import FancyMediaContentSeven from "./assets/FancyMediaContentSeven.webp"
import FancyMediaContentEight from "./assets/FancyMediaContentEight.webp"
import FancyMediaContentNine from "./assets/FancyMediaContentNine.webp"
import FancyMediaContentTen from "./assets/MediaContentTen.webp"
import FancyMediaContentEleven from "./assets/FancyMediaContentEleven.webp"
import FancyMediaContentTwelve from "./assets/FancyMediaContentTwelve.webp"
import FancyMediaContentThirtTeen from "./assets/FancyMediaContentThirtTeen.webp"
import FancyMediaContentFourTeen from "./assets/FancyMediaContentFourTeen.webp"
import FancyMediaContentFiveTeen from "./assets/FancyMediaContentFiftTeen.webp"
import FancyMediaContentSixTeen from "./assets/FancyMediaContentSixTeen.webp"

import styles from "./styles.module.scss"

export const headingTextareaAndPrompt = [
	{
		heading:
			"От маленького чайного бизнеса «для своих» до чайной-кальянной в центре Воронежа",
		textarea: (
			<>
				Я&nbsp;начал заниматься чаем в&nbsp;переломный момент: 2008&nbsp;год,
				бизнес ощущал на&nbsp;себе последствия мирового кризиса.
				В&nbsp;то&nbsp;время я&nbsp;работал управляющим в&nbsp;магазине
				керамической плитки&nbsp;&mdash; дела там шли не&nbsp;очень, и&nbsp;было
				понятно, что надо что-то менять. Выбор у&nbsp;меня был между тем, чтобы
				вернуться на&nbsp;более низкую ступеньку&nbsp;&mdash; устроиться
				в&nbsp;какой-нибудь магазин продавцом, пойти работать грузчиком или
				таксовать&nbsp;&mdash; или попробовать сделать что-то своё.
				<br />
				<br />
				К&nbsp;тому моменту чаем я&nbsp;уже интересовался, сам его пил.
				И&nbsp;решил&nbsp;&mdash; буду торговать, параллельно продолжая работать
				в&nbsp;магазине плитки. В&nbsp;багажнике машины у&nbsp;меня был
				расфасованный чай, купленный у&nbsp;китайцев-оптовиков&nbsp;&mdash;
				я&nbsp;развозил его знакомым и&nbsp;друзьям, выстроил такую цепочку
				продаж. А&nbsp;потом так легли звёзды, что появился человек, который
				сказал: &laquo;Хочешь, я&nbsp;тебе дам денег? Давай вместе
				поторгуем&raquo;. И&nbsp;я&nbsp;решил попробовать. На&nbsp;тот момент
				это показалось мне самым простым выходом. Первая небольшая чайная,
				которую мы&nbsp;открыли в&nbsp;торговом центре, просуществовала год.
				Почти всё это время мы&nbsp;уходили в&nbsp;минус и&nbsp;в&nbsp;итоге
				закрылись.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconOne)}>
				<img
					className={classNames(styles.icon, styles.icon__one)}
					src={PromptIconOne}
					alt="Иконка 1"
					loading="lazy"
				/>
				<p>
					Обычная чайная — это <br className={styles.contentSmallHide} /> не про{" "}
					<br className={styles.contentSmallShow} /> бизнес, а про поддержку
					штанов
				</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentOne)}>
				<Fancybox>
					<a
						data-fancybox="image1"
						href={FancyMediaContentOne}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentOne}
							alt="интерьер чайной Золотой Жук"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descOne)}>
					Звёзды так легли, что инвестор нашёл меня сам
				</p>
			</div>
		),
		textarea2: (
			<>
				Вторая чайная была гораздо более успешным проектом. Она тоже была
				небольшой&nbsp;&mdash; на&nbsp;50&nbsp;м2, но&nbsp;в&nbsp;отдельном
				помещении на&nbsp;проспекте Революции в&nbsp;центре Воронежа. Может
				быть, я&nbsp;и&nbsp;продолжил&nbsp;бы ей&nbsp;заниматься, но, скорее
				всего, роста бизнеса там&nbsp;бы не&nbsp;было. Таких чайных проектов
				в&nbsp;России много. Но&nbsp;это скорее не&nbsp;про бизнес, а&nbsp;про
				поддержку штанов. В&nbsp;новом месте тоже в&nbsp;центре Воронежа,
				на&nbsp;улице Феоктистова, мы&nbsp;с&nbsp;партнёром решили сразу, что
				будем открывать не&nbsp;только чайную, но&nbsp;и&nbsp;кальянную.
				В&nbsp;одном здании, но&nbsp;с&nbsp;двумя разными залами:
				кальянная&nbsp;&mdash; на&nbsp;первом этаже, чайная&nbsp;&mdash;
				в&nbsp;подвальном помещении. Такое расположение залов выбрали из-за
				вытяжки&nbsp;&mdash; на&nbsp;первом этаже система была мощнее, что важно
				для работы кальянной.
			</>
		),
	},
	{
		heading: "Сделали кучу ошибок — «купили» опыт",
		textarea: (
			<>
				В&nbsp;процессе мы&nbsp;наделали кучу ошибок&nbsp;&mdash;
				и&nbsp;в&nbsp;планировании бюджета, и&nbsp;в&nbsp;оформлении интерьера,
				и&nbsp;в&nbsp;концепции заведения. Мы&nbsp;не&nbsp;очень внимательно
				считали бюджет. Если&nbsp;бы изначально вложили на&nbsp;50% больше,
				могли&nbsp;бы заказать услуги дизайнера и&nbsp;сразу сделать нормальный
				интерьер в&nbsp;зале кальянной. В&nbsp;итоге к&nbsp;нам в&nbsp;команду
				пришёл креативный человек. Рутинную работу он&nbsp;не&nbsp;любил,
				но&nbsp;оказался хорошим &laquo;запускателем&raquo; проектов.
				Он&nbsp;нашёл дизайнера, всё переделал в&nbsp;интерьере
				и&nbsp;пространство преобразилось&nbsp;&mdash; стало уютным, тёплым
				и&nbsp;комфортным для гостей.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconTwo)}>
				<img
					className={classNames(styles.icon, styles.icon__two)}
					src={PromptIconTwo}
					alt="Иконка 2"
					loading="lazy"
				/>
				<p className={styles.contentDesktop}>
					У меня ведь нет высшего <wbr /> образования. Я — двоечник
					{/*У&nbsp;меня ведь нет <br className={styles.contentTablet} /> высшего{" "}*/}
					{/*<br className={styles.contentDesktop} /> образования.{" "}*/}
					{/*<br className={styles.contentTablet} /> Я&nbsp;&mdash;&nbsp;двоечник*/}
				</p>
				<p className={styles.contentTablet}>
					У меня ведь нет <wbr /> высшего образования. <wbr /> Я — двоечник
				</p>
				<p className={styles.contentMobile}>
					У меня ведь нет высшего <wbr /> образования. Я — двоечник
				</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent__container,
					styles.mediaContent__containerFirst
				)}
			>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image2"
							href={FancyMediaContentTwo}
							className={styles.media}
						>
							<img
								src={MediaContentTwo}
								alt="пространство в чайной Золотой Жук"
								loading="lazy"
							/>
						</a>
					</Fancybox>

					<p className={classNames(styles.desc, styles.descTwo)}>
						У нас уютное пространство
					</p>
				</div>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image3"
							href={FancyMediaContentThree}
							className={styles.media}
						>
							<img
								src={MediaContentThree}
								alt="дизайн интерьера чайной"
								loading="lazy"
							/>
						</a>
					</Fancybox>
					<p className={classNames(styles.desc, styles.descThree)}>
						Интерьер делал дизайнер
					</p>
				</div>
			</div>
		),
		textarea2: (
			<>
				Ещё две наши ошибки. В&nbsp;2017&nbsp;году, когда открывались, был моден
				вейп, и&nbsp;мы&nbsp;пытались продавать и&nbsp;его. Но&nbsp;это
				не&nbsp;пошло. В&nbsp;начале мы&nbsp;не&nbsp;очень понимали
				в&nbsp;кальянах, и&nbsp;они получались плохими. Уже потом оплатили
				обучение нашим сотрудникам, и&nbsp;с&nbsp;кальянами у&nbsp;нас всё
				наладилось. В&nbsp;результате, я&nbsp;считаю, что
				мы&nbsp;&laquo;купили&raquo; опыт на&nbsp;всех этих ошибках. У&nbsp;меня
				ведь нет высшего образования. Я&nbsp;&mdash; двоечник. В&nbsp;девятом
				классе меня оставляли на&nbsp;второй год. Всё, что
				я&nbsp;на&nbsp;сегодня знаю и&nbsp;умею,&nbsp;&mdash; это чисто опыт.
				Документального подтверждения знаний у&nbsp;меня нет.
			</>
		),
	},
	{
		heading: "Хороший чай — не элитный напиток, а просто продукт питания",
		textarea: (
			<>
				Вокруг чая ходит много мифов и&nbsp;легенд, которые транслируют чайные
				мастера и&nbsp;продавцы по&nbsp;ту&nbsp;сторону прилавка. Развенчание
				этих мифов&nbsp;&mdash; это&nbsp;то, чем я&nbsp;до&nbsp;сих пор люблю
				заниматься. Например, есть легенда, что чёрный пуэр хранят в&nbsp;земле,
				закладывают в&nbsp;пещеры, и&nbsp;именно поэтому у&nbsp;него такой
				специфический вкус. Когда-то я&nbsp;сам эту легенду взахлёб жене
				пересказывал. Ещё одна популярная легенда связана с&nbsp;молочным
				улуном. Якобы корни чайного дерева поливают молоком. Или иногда
				рассказывают, что готовый чай пропаривают над молоком кокоса. Ничего
				общего с&nbsp;реальностью это не&nbsp;имеет. В&nbsp;России
				в&nbsp;основном продают ароматизированный молочный улун, при
				этом&nbsp;&mdash; не&nbsp;натуральным молоком, а&nbsp;ароматизатором.
				<br />
				<br />
				Чайная культура преподносится как нечто доступное не&nbsp;для всех,
				окутанное ореолом таинственности. Но&nbsp;на&nbsp;самом деле
				чай&nbsp;&mdash; это просто продукт питания. Он&nbsp;может быть плохим
				или хорошим, нравиться мне или нет. Всё остальное&nbsp;&mdash; наносное.
				Флёр элитарности и&nbsp;недоступности вокруг чая создаёт маркетинг.
				Через определённое позиционирование тебе как будто предлагают
				приобщиться к&nbsp;чему-то, что тебя приподнимает в&nbsp;глазах
				окружающих и&nbsp;самого себя. Но&nbsp;это не&nbsp;так.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconThree)}>
				<img
					className={classNames(styles.icon, styles.icon__three)}
					src={PromptIconThree}
					alt="Иконка 3"
					loading="lazy"
				/>
				<p>
					Флёр недоступности <br className={styles.contentSmallHide} /> вокруг
					чая <br className={styles.contentSmallShow} /> создаёт маркетинг
				</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent__container,
					styles.mediaContent__containerSecond
				)}
			>
				<div
					className={classNames(
						styles.mediaContent,
						styles.mediaContent__double
					)}
				>
					<Fancybox>
						<a
							data-fancybox="image4"
							href={FancyMediaContentFour}
							className={styles.media}
						>
							<img
								src={MediaContentFour}
								alt="чайная церемония"
								loading="lazy"
							/>
						</a>
					</Fancybox>

					<p className={classNames(styles.desc, styles.descFour)}>
						В соцсетях и на сайте чай мы показываем эффектно
					</p>
				</div>
				<div
					className={classNames(
						styles.mediaContent,
						styles.mediaContent__double
					)}
				>
					<Fancybox>
						<a
							data-fancybox="image5"
							href={FancyMediaContentFive}
							className={styles.media}
						>
							<img
								src={MediaContentFive}
								alt="чайная церемония в Золотом Жуке"
								loading="lazy"
							/>
						</a>
					</Fancybox>
					<p className={classNames(styles.desc, styles.descFive)}>
						«Золотой Жук» — не закрытый клуб
					</p>
				</div>
			</div>
		),
		textarea2: (
			<>
				В&nbsp;каком-нибудь чайном магазине вам могут рассказывать, что
				&laquo;этот чай собран на&nbsp;высоте в&nbsp;тысячи метров монахами
				в&nbsp;самый благоприятный лунный день&raquo;. Но&nbsp;пьёшь
				и&nbsp;понимаешь: что за&nbsp;гадость? Мне очень нравятся две китайские
				поговорки: &laquo;выпил&nbsp;&mdash; понял&raquo;
				и&nbsp;&laquo;о&nbsp;совершенстве чая решает рот&raquo;. Они точно
				описывают, что красивые маркетинговые ходы не&nbsp;всегда работают.
				<br />
				<br />
				Чайный вкус можно воспитать&nbsp;&mdash; для этого нужно пить много
				разного чая. Только тогда ты&nbsp;начнёшь понимать, что вот это
				высококлассный продукт, обладающий интересным букетом, ароматом, сможешь
				разложить его на&nbsp;составляющие. Можно научиться заваривать чай.
				Но&nbsp;необязательно устраивать чайную церемонию и&nbsp;использовать
				особую посуду. Китайский поэт и&nbsp;писатель Лу&nbsp;Юй, который первым
				систематизировал научные и&nbsp;практические знания о&nbsp;чае, говорил:
				&laquo;Если у&nbsp;меня нет чайника из&nbsp;нефрита, это не&nbsp;значит,
				что у&nbsp;меня утром в&nbsp;чашке не&nbsp;должно быть хорошего
				чая&raquo;.
			</>
		),
	},
	{
		heading: "С гостями мы на равных",
		textarea: (
			<>
				Я&nbsp;ненавижу, и&nbsp;у&nbsp;меня под запретом слово
				&laquo;элитный&raquo;&nbsp;&mdash; как в&nbsp;речи консультантов, так
				и&nbsp;в&nbsp;соцсетях. У&nbsp;меня нет элитного чая. У&nbsp;меня просто
				китайский чай. Люди могут подобрать у&nbsp;нас продукт на&nbsp;свой
				кошелёк, могут начать пробовать что-то новое и&nbsp;необычное.
				Мы&nbsp;стараемся работать с&nbsp;гостями так, чтобы они
				не&nbsp;стеснялись того, что знают про чай меньше, чем&nbsp;мы.
				Я&nbsp;сам когда-то про чай не&nbsp;знал ничего. Да, на&nbsp;сегодня
				у&nbsp;меня есть какие-то знания. Но&nbsp;я&nbsp;готов ими делиться,
				а&nbsp;не&nbsp;надувать щеки и&nbsp;говорить &laquo;вы&nbsp;ничего
				не&nbsp;понимаете&raquo;. Мои сотрудники так&nbsp;же&nbsp;&mdash;
				спокойно и&nbsp;без снобизма объясняют гостям про особенности разных
				чаёв.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFour)}>
				<img
					className={classNames(styles.icon, styles.icon__four)}
					src={PromptIconFour}
					alt="Иконка 4"
					loading="lazy"
				/>
				<p>
					С гостями мы <br className={styles.contentTablet} /> на равных
				</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentSix)}>
				<Fancybox>
					<a
						data-fancybox="image6"
						href={FancyMediaContentSix}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentSix}
							alt="гости чайной"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descSix)}>
					У меня нет элитного чая — у меня просто китайский чай
				</p>
			</div>
		),
		textarea2: (
			<>
				У&nbsp;нас даже в&nbsp;меню нет такого понятия как чайная церемония.
				Есть чаепитие. Даже если приходит новичок, который не&nbsp;умеет
				обращаться с&nbsp;этими китайскими прибамбасами для пития чая, это дело
				пяти минут его научить. И&nbsp;в&nbsp;следующий раз он&nbsp;уже приходит
				и&nbsp;всё делает сам. С&nbsp;гостями мы&nbsp;на&nbsp;равных. Даже если
				они знают меньше, наша задача&nbsp;&mdash; их&nbsp;подтянуть. Я&nbsp;сам
				постоянно продолжаю учиться, узнавать про чай что-то новое.
				И&nbsp;предлагаю своим сотрудникам и&nbsp;гостям узнавать новое вместе.
				Вместе&nbsp;же интереснее.
			</>
		),
	},
	{
		heading:
			"К нам приходят самые разные люди, но 70% гостей — мужчины в возрасте 25–45 лет",
		textarea: (
			<>
				Наша чайная&nbsp;&mdash; это бар без алкоголя. В&nbsp;бар кто-то
				приходит просто &laquo;накатить&raquo; кружечку пива,
				кто-то&nbsp;&mdash; с&nbsp;компанией провести время, поговорить про
				футбол или ещё про что-то. У&nbsp;нас нет алкогольной составляющей,
				но&nbsp;тоже есть атмосфера домашности и&nbsp;доступности. Многих гостей
				я&nbsp;знаю по&nbsp;именам, кого-то&nbsp;&mdash; просто в&nbsp;лицо.
				К&nbsp;нам приходят абсолютно разные люди&nbsp;&mdash; начиная
				от&nbsp;простых грузчиков, которые не&nbsp;сидят в&nbsp;чайной,
				а&nbsp;покупают чай домой, заканчивая работниками администрации.
				<br />
				<br />
				Однажды я&nbsp;наблюдал картину: за&nbsp;одним столом сидели блатные,
				за&nbsp;вторым&nbsp;&mdash; йоги, и&nbsp;за&nbsp;третьим&nbsp;&mdash;
				хипстеры. Каждая из&nbsp;этих компаний вела свои разговоры, и&nbsp;они
				друг другу не&nbsp;мешали. Где ещё такая разношёрстная публика может
				объединиться? Приходят молодые люди 18&ndash;19&nbsp;лет, заказывают
				пуэрчик, играют в&nbsp;настольные игры. Есть парочки. Есть постоянный
				гость, который всегда приходит один&nbsp;&mdash; сидит час-два, пьёт
				два-три чая и&nbsp;в&nbsp;наушниках что-то смотрит в&nbsp;телефоне.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFive)}>
				<img
					className={classNames(styles.icon, styles.icon__five)}
					src={PromptIconFive}
					alt="Иконка 5"
					loading="lazy"
				/>
				<p>
					У нас разные гости — <br className={styles.contentSmallHide} /> и
					блатные, <br className={styles.contentSmallShow} /> и йоги,{" "}
					<br className={styles.contentSmallHide} /> и хипстеры
				</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContentSeven)}
			>
				<Fancybox>
					<a
						data-fancybox="image7"
						href={FancyMediaContentSeven}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentSeven}
							alt="бар без алкоголя"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descSeven)}>
					Наша чайная — это бар без алкоголя
				</p>
			</div>
		),
		textarea2: (
			<>
				Но&nbsp;порядка&nbsp;70% наших гостей&nbsp;&mdash; мужчины
				25&ndash;45&nbsp;лет. Я&nbsp;не&nbsp;знаю, почему это так и&nbsp;пока
				не&nbsp;могу сегментировать нашу аудиторию ещё как-нибудь, кроме как
				по&nbsp;половому признаку и&nbsp;возрасту. А&nbsp;сегментировать важно,
				чтобы давать рекламу именно на&nbsp;ту&nbsp;аудиторию, которая
				её&nbsp;ждёт. Новых посетителей мы&nbsp;привлекаем как раз через
				таргетированную рекламу в&nbsp;соцсетях и&nbsp;сарафанное радио.
				Удерживаем гостей благодаря качеству продукта и&nbsp;сервиса.
			</>
		),
	},
	{
		heading:
			"О перспективах роста сети и сохранении аутентичности концепции «Золотого Жука»",
		textarea: (
			<>
				Я&nbsp;хочу растить сеть и&nbsp;не&nbsp;боюсь, что проект потеряет свою
				уникальность. На&nbsp;сегодня &laquo;Золотой Жук&raquo; есть
				в&nbsp;Донецке, в Курске и&nbsp;Ульяновске. А ещё осенью 2022 года мы
				открылись в Ярославле и Калининграде. В&nbsp;Донецке работают уже две
				чайные-кальянные. Когда я&nbsp;спрашиваю у&nbsp;ребят, почему у&nbsp;них
				всё получается, они говорят: &laquo;Саш, мы&nbsp;тебя слушаем&raquo;.
				Выходит, что моя концепция, моё видение бизнеса работают.
				<br />
				<br />
				А&nbsp;вот в&nbsp;Рязани попробовали, и&nbsp;не&nbsp;пошло. Там был как
				раз тот случай, когда у&nbsp;человека были деньги, и&nbsp;он&nbsp;решил
				инвестировать, открыв чайную. Но&nbsp;это не&nbsp;тот проект, который
				сам по&nbsp;себе работает. Им&nbsp;нужно заниматься. Я&nbsp;уверен, что
				если не&nbsp;следовать моей концепции, проект не&nbsp;выживет. Когда
				я&nbsp;выйду в&nbsp;полноценную франшизу, для меня будет очень важно
				беседовать с&nbsp;потенциальными франчайзи. Я&nbsp;не&nbsp;буду
				продавать право открыть &laquo;Золотой Жук&raquo; просто так. Мне важно,
				кто хочет купить. Это ведь моё детище.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSix)}>
				<img
					className={classNames(styles.icon, styles.icon__six)}
					src={PromptIconSix}
					alt="Иконка 6"
					loading="lazy"
				/>
				{/*<p>*/}
				{/*	Качество продукта <br className={styles.contentSmallHide} /> и сервис*/}
				{/*	— составляющие <br className={styles.contentSmallHide} /> развития*/}
				{/*	бизнеса*/}
				{/*</p>*/}
				<p className={styles.contentDesktop}>
					Качество продукта <wbr /> и сервис — составляющие <wbr /> развития
					бизнеса
				</p>
				<p className={classNames(styles.contentTablet, styles.text_ten)}>
					Качество продукта <wbr /> и сервис — <wbr /> составляющие <wbr />{" "}
					развития бизнеса
				</p>
				<p className={styles.contentMobile}>
					Качество продукта и сервис — <wbr /> составляющие развития бизнеса
				</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContentEight)}
			>
				<Fancybox>
					<a
						data-fancybox="image8"
						href={FancyMediaContentEight}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentEight}
							alt="концепция чайной"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descEight)}>
					«Золотой Жук» сам по себе не работает — им нужно заниматься
				</p>
			</div>
		),
		textarea2: (
			<>
				В&nbsp;целом, моя концепция&nbsp;&mdash; это внимательный дружелюбный
				сервис высокого уровня, дифференцированный подход к&nbsp;гостям
				(с&nbsp;кем-то можно пошутить, с&nbsp;кем-то нужно держаться
				на&nbsp;расстоянии) и&nbsp;качество самого продукта. Благодаря этому
				люди к&nbsp;нам идут, несмотря на&nbsp;то&nbsp;что у&nbsp;нас средний
				ценник по&nbsp;городу немного выше, чем у&nbsp;остальных. Качество
				продукта и&nbsp;сервис&nbsp;&mdash; это принципиальные составляющие
				развития бизнеса. Неважно, ресторатор вы&nbsp;или делаете журнальные
				столики. Если их&nbsp;не&nbsp;будет, не&nbsp;получится
				и&nbsp;долгоиграющего интересного и&nbsp;нужного людям проекта.
			</>
		),
	},
	{
		heading: "Чай чаю рознь: какие сорта я покупаю",
		textarea: (
			<>
				На&nbsp;полках у&nbsp;нас бывает до&nbsp;300 видов чая. Но&nbsp;обычно
				я&nbsp;покупаю что-то ходовое, редко&nbsp;&mdash; что-то дорогое
				и&nbsp;не&nbsp;очень востребованное. Гости больше всего любят
				пуэры&nbsp;&mdash; они самые известные и&nbsp;на&nbsp;слуху. А,
				например, хороший японский чай сенча стоит дорого и&nbsp;продаётся
				долго. Я&nbsp;не&nbsp;могу позволить себе заморозить 5, 10,
				50&nbsp;тысяч на&nbsp;очень длительный срок, потому что
				знаю&nbsp;&mdash; пара-тройка ящиков пуэра продадутся у&nbsp;меня
				в&nbsp;три раза быстрее, чем дорогая сенча.
				<br />
				<br />
				Правда, из&nbsp;последней поездки в&nbsp;Китай в&nbsp;2018 году
				я&nbsp;привёз чай, килограмм которого у&nbsp;меня стоит
				70&nbsp;000&nbsp;рублей. Но&nbsp;пять грамм этого чая могут выдать
				десять литров готового напитка. Это для тех, кто понимает, за&nbsp;что
				он&nbsp;платит. Это чай из&nbsp;экологически чистого региона Бань Чжан,
				собранный со&nbsp;старых деревьев по&nbsp;700&ndash;800&nbsp;лет,
				приготовленный вручную, высушенный на&nbsp;солнце. Органолептика
				у&nbsp;него сумасшедшая.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSeven)}>
				<img
					className={classNames(styles.icon, styles.icon__seven)}
					src={PromptIconSeven}
					alt="Иконка 7"
					loading="lazy"
				/>
				<p>
					На полках «Золотого <br className={styles.contentSmallHide} /> Жука»
					до 300 видов чая
				</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentNine)}>
				<Fancybox>
					<a
						data-fancybox="image9"
						href={FancyMediaContentNine}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentNine}
							alt="чайные сорта"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descNine)}>
					Из Китая я привёз чай, килограмм которого у меня стоит 70 000 рублей
				</p>
			</div>
		),
		textarea2: (
			<>
				Гости, конечно, спрашивают, почему у&nbsp;вас есть Тегуанинь
				за&nbsp;1&nbsp;000 и&nbsp;за&nbsp;3&nbsp;000&nbsp;рублей?
				Мы&nbsp;на&nbsp;пальцах рассказываем, что здесь&nbsp;&mdash; массовый
				продукт, а&nbsp;тут&nbsp;&mdash; перебранный, качеством выше. Иногда
				приходится использовать ассоциации: &laquo;Вот есть запорожец
				и&nbsp;есть мерседес. И&nbsp;то&nbsp;и&nbsp;то&nbsp;машина. Но&nbsp;едут
				по-разному. Также и&nbsp;чай. Есть что-то в&nbsp;пакетиках.
				А&nbsp;есть&nbsp;то, что у&nbsp;нас стоит на&nbsp;полке.
				И&nbsp;то&nbsp;и&nbsp;то&nbsp;чай. Но&nbsp;качество разное. Вкусовые
				ощущение разные. Органолептика разная. Разное воздействие
				на&nbsp;организм&raquo;.
				<br />
				<br />
				У&nbsp;нас есть минимальный набор к&nbsp;чаю&nbsp;&mdash; орешки, манго,
				эклеры. Но&nbsp;еды нет. Мы&nbsp;пробовали ввести в&nbsp;меню еду,
				но&nbsp;так и&nbsp;не&nbsp;смогли начать зарабатывать на&nbsp;этом,
				поэтому отказались от&nbsp;кухни. Кухня&nbsp;&mdash; это отдельное
				направление. Для её&nbsp;запуска нужны специальные знания
				и&nbsp;отдельные инвестиции.
			</>
		),
	},
	{
		heading: "Охотиться за редким чаем в Китае или покупать в России?",
		textarea: (
			<>
				Если кто-то говорит, что сам ездит в&nbsp;Китай покупать чай, мне сразу
				хочется спросить, сколько стоит перелёт в&nbsp;Китай, сколько стоит
				пожить и&nbsp;поездить в&nbsp;Китае? Ведь все эти деньги закладываются
				в&nbsp;конечный продукт. Когда я&nbsp;начинал продавать чай, всё покупал
				в&nbsp;России. Сегодня процентов 90&nbsp;моих заказов&nbsp;&mdash;
				напрямую из&nbsp;Китая.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconEight)}>
				<img
					className={classNames(styles.icon, styles.icon__eight)}
					src={PromptIconEight}
					alt="Иконка 8"
					loading="lazy"
				/>
				<p>
					Чай собирается <br className={styles.contentSmallHide} /> с деревьев{" "}
					<br className={styles.contentSmallShow} /> возрастом{" "}
					<br className={styles.contentSmallHide} /> 700–1000 лет
				</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentTen)}>
				<Fancybox>
					<a
						data-fancybox="image10"
						href={FancyMediaContentTen}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentTen}
							alt="завод по сбору чая"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descTen)}>
					С совладельцем завода по сбору и обработке чайных листьев{" "}
					<br className={styles.contentSmallShow} /> в Банчжане
				</p>
			</div>
		),
		textarea2: (
			<>
				Мне подфартило у&nbsp;меня есть знакомый, очень сильный китаевед,
				в&nbsp;совершенстве владеет китайским языком и&nbsp;его наречиями.
				Он&nbsp;меня познакомил с&nbsp;нужными китайцами и&nbsp;помогает
				формировать заказы и&nbsp;оформлять договора: пишу, что мне нужно,
				а&nbsp;китайцы на&nbsp;месте закупают и&nbsp;отправляют выбранный чай.
				Я&nbsp;не&nbsp;трачу лишние деньги на&nbsp;поездку
				и&nbsp;не&nbsp;охочусь за&nbsp;редким чаем.
			</>
		),
	},
	{
		heading: "Нанимать управляющего пока нет смысла",
		textarea: (
			<>
				Сегодня я&nbsp;не&nbsp;только владелец бизнеса,
				но&nbsp;и&nbsp;управляющий. Нанимать сотрудника на&nbsp;эту должность
				нет смысла. По&nbsp;воронежским меркам ему надо будет платить примерно
				40&nbsp;000&nbsp;рублей. Зачем? Что я&nbsp;тогда буду делать? У&nbsp;нас
				пока не&nbsp;такой объём, который&nbsp;бы требовал делегировать
				управление. Верю, что когда откроется ещё несколько заведений,
				я&nbsp;буду спокойно сидеть в&nbsp;офисе и&nbsp;заниматься только
				стратегическим планированием, а&nbsp;тактическое&nbsp;&mdash; передам
				наёмным управляющим.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconNine)}>
				<img
					className={classNames(styles.icon, styles.icon__nine)}
					src={PromptIconNine}
					alt="Иконка 9"
					loading="lazy"
				/>
				<p>
					Важно контролировать <br className={styles.contentSmallHide} />{" "}
					сотрудников, <br className={styles.contentSmallShow} /> но не{" "}
					<br className={styles.contentSmallHide} /> «пережимать»
				</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent__container,
					styles.mediaContent__containerThird
				)}
			>
				<div
					className={classNames(
						styles.mediaContent__menu,
						styles.mediaContent__menu_one
					)}
				>
					<div>
						<Fancybox>
							<a
								data-fancybox="image11"
								href={FancyMediaContentEleven}
								className={styles.media}
							>
								<img
									src={MediaContentEleven}
									alt="меню в чайной"
									loading="lazy"
								/>
							</a>
						</Fancybox>
					</div>
				</div>
				<div
					className={classNames(
						styles.mediaContent__menu,
						styles.mediaContent__menu_two
					)}
				>
					<div>
						<Fancybox>
							<a
								data-fancybox="image12"
								href={FancyMediaContentTwelve}
								className={styles.media}
							>
								<img
									src={MediaContentTwelve}
									alt="чайные коктейли"
									loading="lazy"
								/>
							</a>
						</Fancybox>
					</div>
				</div>
				<p className={classNames(styles.desc, styles.descTwelve)}>
					Кроме чая мы предлагаем <wbr /> гостям коктейли &mdash; <wbr /> смеси
					разных сортов чая
				</p>
			</div>
		),
		textarea2: (
			<>
				Совсем мелкую рутину&nbsp;&mdash; заказ чая, канцтоваров, орехов,
				эклеров&nbsp;&mdash; получилось делегировать. И&nbsp;в&nbsp;кальянной,
				и&nbsp;в&nbsp;чайной есть старшие. Сейчас у&nbsp;меня ненормированный
				рабочий день. Чайная-кальянная открывается в&nbsp;три часа дня.
				Я&nbsp;могу приехать утром в&nbsp;10&ndash;12 и&nbsp;до&nbsp;19&ndash;20
				вечера я&nbsp;здесь. Последние пару-тройку лет я&nbsp;не&nbsp;бываю
				на&nbsp;работе по&nbsp;выходным. Но&nbsp;когда мы&nbsp;открывали первую
				чайную, я&nbsp;чуть&nbsp;ли не&nbsp;ночевал в&nbsp;ней. Мне жена дочку
				привозила показывать. Но&nbsp;для меня это нормально. Я&nbsp;понимаю,
				что иногда на&nbsp;чашу весов в&nbsp;жизни нужно положить больше работы,
				иногда&nbsp;&mdash; больше на&nbsp;чашу друзей, иногда&nbsp;&mdash;
				семьи. Это живой процесс.
			</>
		),
	},
	{
		heading: "Я спокойно беру на работу людей без опыта",
		textarea: (
			<>
				Всему можно научить. Если у&nbsp;человека есть опыт работы с&nbsp;чаем,
				значит он&nbsp;уже заточен на&nbsp;определённую концепцию.
				А&nbsp;у&nbsp;меня концепция другая. Так что мне проще взять человека
				без опыта, которому просто интересен чай. К&nbsp;тому&nbsp;же если
				у&nbsp;человека уже есть опыт работы в&nbsp;чайной, у&nbsp;меня сразу
				возникает вопрос, почему он&nbsp;оттуда ушёл? Если ему мало платили,
				но&nbsp;он&nbsp;там долго работал, значит, он&nbsp;воровал.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconTen)}>
				<img
					className={classNames(styles.icon, styles.icon__ten)}
					src={PromptIconTen}
					alt="Иконка 10"
					loading="lazy"
				/>
				{/*<p>*/}
				{/*	Выручка за месяц по <br className={styles.contentSmallHide} />{" "}*/}
				{/*	заведению — <br className={styles.contentSmallShow} /> 1,3 млн{" "}*/}
				{/*	<br className={styles.contentSmallHide} /> рублей*/}
				{/*</p>*/}
				<p className={classNames(styles.contentDesktop, styles.text_ten)}>
					Выручка за месяц по <wbr /> заведению — 1,3 млн <wbr /> рублей
				</p>
				<p className={styles.contentTablet}>
					Выручка за месяц <wbr /> по заведению — <wbr /> 1,3 млн рублей
				</p>
				<p className={styles.contentMobile}>
					Выручка за месяц по заведению — <wbr /> 1,3 млн рублей
				</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent__container,
					styles.mediaContent__containerSecond
				)}
			>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image13"
							href={FancyMediaContentThirtTeen}
							className={styles.media}
						>
							<img
								src={MediaContentThirtTeen}
								alt="работа в чайной"
								loading="lazy"
							/>
						</a>
					</Fancybox>

					<p className={classNames(styles.desc, styles.descThreeTeen)}>
						В сотрудниках мне важны открытость
					</p>
				</div>
				<div className={styles.mediaContent}>
					<Fancybox>
						<a
							data-fancybox="image14"
							href={FancyMediaContentFourTeen}
							className={styles.media}
						>
							<img
								src={MediaContentFourTeen}
								alt="персонал в чайной"
								loading="lazy"
							/>
						</a>
					</Fancybox>
					<p className={classNames(styles.desc, styles.descFourTeen)}>
						А ещё — умение идти на контакт
					</p>
				</div>
			</div>
		),
		textarea2: (
			<>
				Я&nbsp;готов брать людей и&nbsp;с&nbsp;опытом, но&nbsp;в&nbsp;любом
				случае делаю выводы после общения. С&nbsp;кальянной проще&nbsp;&mdash;
				там можно протестировать, насколько качественно человек делает кальяны.
				В&nbsp;сотрудниках мне важны открытость, умение идти на&nbsp;контакт,
				признавать свои ошибки, учиться новому. Хорошо, если я&nbsp;увижу огонёк
				в&nbsp;глазах, увижу, что человеку интересно, что он&nbsp;хочет этим
				заниматься.
			</>
		),
	},
	{
		heading: "Контроль за персоналом необходим",
		textarea: (
			<>
				Иногда я&nbsp;слишком доверяю людям. У&nbsp;меня работала управляющей
				девушка, которая проворовалась. Я&nbsp;поймал её&nbsp;за&nbsp;руку.
				Упиралась до&nbsp;последнего, била себя &laquo;пяткой
				в&nbsp;грудь&raquo;, что не&nbsp;брала. В&nbsp;итоге пару месяцев назад
				был суд. Самое обидное, что она работала со&nbsp;мной
				в&nbsp;те&nbsp;моменты, когда у&nbsp;меня не&nbsp;было денег. И, чтобы
				заплатить им&nbsp;зарплаты, я&nbsp;снимал деньги с&nbsp;личной кредитки.
				Для меня это был большой удар, настоящее предательство.
				<br />
				<br />
				Важно контролировать сотрудников,
				но&nbsp;не&nbsp;&laquo;пережимать&raquo;. Не&nbsp;нужно требовать отчёта
				за&nbsp;всё подряд, но&nbsp;контроль всё-таки необходим. Потому что это
				деньги. К&nbsp;сожалению, в&nbsp;наше время &laquo;ценная резаная
				бумага&raquo; решает очень много. Я&nbsp;делаю проверки тайным
				покупателем. Это объективная оценка, которую даёт посторонний человек.
				И&nbsp;своим ребятам мне проще указать на&nbsp;ошибки. Не&nbsp;сделать
				им&nbsp;выговор, а&nbsp;побеседовать и&nbsp;сказать: &laquo;Вот здесь
				ты&nbsp;ошибся, здесь стоило&nbsp;бы сделать по-другому&raquo;.
				К&nbsp;таким мерам как &laquo;накачка&raquo; или
				&laquo;головомойка&raquo; я&nbsp;стараюсь прибегать в&nbsp;последнюю
				очередь. Мне кажется, важно с&nbsp;людьми разговаривать
				и&nbsp;доносить&nbsp;&mdash; почему что-то важно делать так,
				а&nbsp;не&nbsp;иначе. Но&nbsp;я&nbsp;готов выслушивать и&nbsp;принимать
				какие-то идеи и&nbsp;от&nbsp;сотрудников по&nbsp;поводу общей концепции
				заведения, взаимоотношений с&nbsp;гостями.
			</>
		),
	},
	{
		heading:
			"Почему я перешёл в оплате сотрудникам на систему «процент от выручки»",
		textarea: (
			<>
				У&nbsp;нас прогрессивная шкала&nbsp;&mdash; чем больше выручка, тем
				больше процент. И&nbsp;границ для заработка нет. А&nbsp;раньше был
				базовый оклад плюс премии. Мне кажется, что оклад меньше мотивирует.
				План выполнил или не&nbsp;выполнил, оклад я&nbsp;всё равно получу.
				А&nbsp;так в&nbsp;интересах сотрудников требовать от&nbsp;меня что-то
				придумывать, повышать качество продукта. Так и&nbsp;они могут больше
				заработать, и&nbsp;я&nbsp;больше прибыли получить.
			</>
		),
		prompt: (
			<div
				className={classNames(styles.promptTypeOne, styles.promptIconEleven)}
			>
				<img
					className={classNames(styles.icon, styles.icon__eleven)}
					src={PromptIconEleven}
					alt="Иконка 11"
					loading="lazy"
				/>
				{/*<p>Выручка за месяц по заведению — 1,6 млн рублей</p>*/}
				<p>
					Прибыль кальянная <br className={styles.contentSmallHide} /> и чайная
					генерируют <br className={styles.contentSmallHide} /> пополам
				</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContentFiveTeen)}
			>
				<Fancybox>
					<a
						data-fancybox="image15"
						href={FancyMediaContentFiveTeen}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentFiveTeen}
							alt="прибыльная чайная"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descFiveTeen)}>
					Был период, когда, чтобы выплатить зарплаты, я&nbsp;снимал деньги
					с&nbsp;кредитки
				</p>
			</div>
		),
		textarea2: (
			<>
				Мы&nbsp;перешли на&nbsp;процент от&nbsp;выручки буквально две недели
				назад, а&nbsp;от&nbsp;ребят уже пошла обратная связь. Например,
				в&nbsp;кальянной у&nbsp;нас есть ароматизированный чай. Они сказали, что
				его надо менять, потому что качество&nbsp;&mdash;
				&laquo;не&nbsp;алё&raquo;. А&nbsp;раньше они этому особо
				не&nbsp;придавали значения. А&nbsp;сейчас настаивают: &laquo;Надо
				менять, давай подбирать, давай ищи новое&raquo;. В&nbsp;общем,
				в&nbsp;них это начало пробуждать в&nbsp;сотрудниках больше инициативы
				и&nbsp;к&nbsp;продукту, и&nbsp;к&nbsp;заведению. А&nbsp;так системы
				мотивации можно выстроить разные. Можно дать минимальный оклад,
				и&nbsp;от&nbsp;определённого порога начинать платить проценты. Здесь
				больше вопрос личных предпочтений.
			</>
		),
	},
	{
		heading: "Показатели бизнеса",
		textarea: (
			<>
				<span className={styles.ul__span}>
					Выручка за месяц по заведению — 1,6 млн рублей, ещё 600 000 рублей
					принёс интернет-магазин.
				</span>
				<br />
				<span>Средний чек за 7 дней:</span>

				<ul className={styles.ul}>
					<li>в чайной — 1 950 рублей;</li>
					<li>в кальянной — 2 400 рублей;</li>
					<li>в целом по заведению — 2 200 рублей;</li>
					<li>в интернет-магазине — 2 321 рублей.</li>
				</ul>
				<br />
				<span className={styles.ul__span}>
					Прибыль кальянная и чайная генерируют пополам: 50% приходится на
					услуги по чаепитию и продаже чая, 50% — на кальяны.
				</span>
				<br />
				<br />
				<span>Нюансы:</span>

				<ul className={styles.ul}>
					<li>
						По интернет-магазину выручка обычно — в районе 700 тысяч рублей, май
						по нашему опыту — провальный месяц для онлайн-продаж.
					</li>
					<li>
						В общей выручке по маржинальности большую долю даёт чаепитие как
						услуга.
					</li>
					<li>
						Ценовая политика на услуги (чаепитие, кальян) менялась несколько
						раз. Например, мы считали цену и просто от количества гостей, и от
						количества часов (как в тайм-кафе), но эти системы не очень хорошо
						работали по экономике и были непонятны для гостей. А сейчас у нас
						есть утверждённая стоимость чаепия на человека и на разное
						количество гостей, которая основана на разной закладке чая по
						техкартам.
					</li>
					<li>
						В связи с последними событиями всё подорожало. Я поднял цены на 20%
						— ровно настолько, насколько у меня выросли затраты. Сейчас цены уже
						опустил на 10%.
					</li>
				</ul>
			</>
		),
		prompt: (
			<div
				className={classNames(styles.promptTypeOne, styles.promptIconTwelve)}
			>
				<img
					className={classNames(styles.icon, styles.icon__twelve)}
					src={PromptIconTwelve}
					alt="Иконка 12"
					loading="lazy"
				/>
				<p>
					Май для онлайн-продаж — <br /> провальный месяц
				</p>
			</div>
		),
	},
	{
		heading: "Система лояльности: бонусы вместо скидок",
		textarea: (
			<>
				Чтобы увеличивать выручку и&nbsp;прибыль, мне нужно было что-то менять.
				Было страшно. В&nbsp;итоге мы&nbsp;отменили скидки, перешли
				на&nbsp;бонусную систему, так как со&nbsp;скидками я&nbsp;терял деньги.
				Пока выручка и&nbsp;прибыль растут. Конечно, наступит момент, когда люди
				начнут расплачиваться бонусами. Но&nbsp;всё равно это выгоднее бизнесу,
				чем давать скидки. Мы&nbsp;честно рассказывали о&nbsp;том, почему так
				сделали, что перед нами стоял выбор&nbsp;&mdash; сильно поднимать цены
				или отменять скидочную систему и&nbsp;переходить на&nbsp;бонусную. Гости
				отнеслись к&nbsp;этому с&nbsp;пониманием. Было небольшое недовольство,
				но&nbsp;негатива не&nbsp;было. В&nbsp;основном, посетители говорят:
				&laquo;Ну&nbsp;хорошо, всё равно будем к&nbsp;вам ходить&raquo;.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconXx)}>
				<img
					className={classNames(styles.icon, styles.icon__thirteen)}
					src={PromptIconThreeTeen}
					alt="Иконка 13"
					loading="lazy"
				/>
				<p>
					Мы отменили скидки <br className={styles.contentSmallHide} /> и
					перешли на <br className={styles.contentSmallShow} /> бонусную{" "}
					<br className={styles.contentSmallHide} /> систему
				</p>
			</div>
		),
	},
	{
		heading: "Автоматизация бизнеса",
		textarea: (
			<>
				Сегодня я&nbsp;уже не&nbsp;представляю, как можно вести бизнес без
				автоматизированной программы учёта, как можно иначе вести учёт складских
				остатков, продаж, не&nbsp;тратя кучу времени. Это серьёзная помощь для
				меня как владельца. Автоматизация помогает с&nbsp;планированием
				и&nbsp;бюджетированием. Можно сразу понять, какой продукт генерирует
				выручку-прибыль, а&nbsp;какой&nbsp;&mdash; нужно запускать в&nbsp;акцию
				и&nbsp;избавляться от&nbsp;него, чтобы эти деньги не&nbsp;были
				заморожены.
			</>
		),
		// prompt: (
		// 	<div
		// 		className={classNames(styles.promptTypeOne, styles.promptIconThreeTeen)}
		// 	>
		// 		<img src={PromptIconThreeTeen} alt="Иконка 13" loading="lazy" />
		// 		<p>Мы&nbsp;отменили скидки и&nbsp;перешли на&nbsp;бонусную систему</p>
		// 	</div>
		// ),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContentSixTeen)}
			>
				<Fancybox>
					<a
						data-fancybox="image16"
						href={FancyMediaContentSixTeen}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentSixTeen}
							alt="система автоматизации кальянной"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descSixTeen)}>
					Автоматизация помогает с&nbsp;планированием и&nbsp;бюджетированием
				</p>
			</div>
		),
		textarea2: (
			<>
				Онлайн-меню Quick Resto очень удобно. У&nbsp;меня нет кухни,
				но&nbsp;дополнения к&nbsp;чаю часто меняются. Каждый раз перепечатывать
				замучаешься, а&nbsp;здесь нажал две кнопки и&nbsp;готово.
				Из&nbsp;неочевидных функций&nbsp;&mdash; это возможность контроля продаж
				и&nbsp;сотрудников. Можно наблюдать за&nbsp;своей целевой аудиторией,
				видеть, кто и&nbsp;как появляется, кто сколько тратит.
			</>
		),
		prompt: (
			<div
				className={classNames(styles.promptTypeOne, styles.promptIconFourTeen)}
			>
				<img
					className={classNames(styles.icon, styles.icon__fourteen)}
					src={PromptIconFourTeen}
					alt="Иконка 14"
					loading="lazy"
				/>
				{/*<p>*/}
				{/*	Закладывай «подушку» на <br className={styles.contentSmallHide} />{" "}*/}
				{/*	полгода <br className={styles.contentSmallShow} /> — на аренду,{" "}*/}
				{/*	<br className={styles.contentSmallHide} /> зарплаты, налоги*/}
				{/*</p>*/}
				<p className={styles.contentDesktop}>
					Закладывай «подушку» на <wbr /> полгода — на аренду, <wbr /> зарплаты,
					налоги
				</p>
				<p
					className={classNames(
						styles.contentTablet
						// styles.icon__fourteenText
					)}
				>
					Закладывай «подушку» <wbr /> на полгода — на аренду, <wbr /> зарплаты,
					налоги
				</p>
				<p className={styles.contentMobile}>
					Закладывай «подушку» на полгода <wbr /> — на аренду, зарплаты, налоги
				</p>
			</div>
		),
	},
	{
		heading: "Советы тем, кто хочет открыть свою чайную",
		textarea: (
			<>
				<strong>Считайте бюджет.</strong> Рассчитайте, какая нужна подушка.
				Учитывайте затраты на&nbsp;оборудование и&nbsp;закупку товара.
				Помещение, скорее всего, будет арендуемое. Закладывайте подушку
				на&nbsp;полгода&nbsp;&mdash; на&nbsp;аренду, зарплаты, налоги. Одна
				из&nbsp;основных составляющих бюджета&nbsp;&mdash; затраты
				на&nbsp;ремонт. Попробуйте поговорить с&nbsp;арендодателем
				и&nbsp;оптимизировать эти расходы. Может быть, что-то
				он&nbsp;компенсирует. А&nbsp;что-то возмещать не&nbsp;будет, скажет
				&laquo;делай сам&raquo;.
				<br />
				<br />
				<strong>Стоит договариваться об&nbsp;арендных каникулах.</strong> Мне
				в&nbsp;этом отношении очень повезло&nbsp;&mdash; мой арендодатель
				в&nbsp;Воронеже очень сильно идёт навстречу. За&nbsp;время пандемии
				мы&nbsp;год платили аренду 50%.
				<br />
				<br />
				<strong>Попытайтесь найти свою концепцию.</strong> Если она похожа
				на&nbsp;другие, ищите свою фишку, с&nbsp;помощью которой можно будет
				привлечь людей. Если у&nbsp;вас есть, чем взять, есть харизма,
				и&nbsp;вы&nbsp;этого не&nbsp;стесняетесь, используйте&nbsp;её. Если
				вы&nbsp;готовы общаться, быть искренними с&nbsp;гостями, выходите
				в&nbsp;свет. Для меня это важная часть работы.
				<br />
				<br />
				<strong>
					Внимательно относитесь к&nbsp;людям, которых вы&nbsp;нанимаете.
				</strong>{" "}
				Относитесь к&nbsp;ним как к&nbsp;своим компаньонам, партнёрам, своей
				команде. Лишние уйдут. Останутся только&nbsp;те, кто будет дорожить тем,
				что они делают.
				<br />
				<br />
				<strong>
					Что касается чая, тут главная рекомендация&nbsp;&mdash; пробовать,
					а&nbsp;не&nbsp;просто выбирать, что подешевле.
				</strong>{" "}
				Чайные проекты появляются и&nbsp;исчезают. Они часто предлагают
				некачественный продукт. Вот человек попробовал пуэр, посмотрел, что
				он&nbsp;стоит в&nbsp;магазине 1&nbsp;000&nbsp;рублей, а&nbsp;потом нашёл
				на&nbsp;AliExpress и&nbsp;пуэр за&nbsp;100&nbsp;рублей. В&nbsp;итоге ему
				присылают гадость, которую пить невозможно. А&nbsp;он&nbsp;закупился
				на&nbsp;50&nbsp;000 рублей и&nbsp;думает: &laquo;Всё, сейчас как
				раскручусь и&nbsp;через год куплю себе рендж ровер последний&raquo;.
				А&nbsp;ни&nbsp;фига, не&nbsp;работает так.
				<br />
				<br />
				Именно поэтому проще начинать работать с&nbsp;российскими поставщиками.
				Тебе либо кто-то предоставит пробники, либо у&nbsp;кого-то
				ты&nbsp;сможешь купить чуть-чуть и&nbsp;понять, что за&nbsp;качество
				продукта. При этом, конечно, нужно самому хоть чуть-чуть пить чай
				и&nbsp;хоть чуть-чуть начать его изучать.
				<br />
				<br />
				К&nbsp;сожалению, это не&nbsp;тот бизнес, в&nbsp;который можно вложить
				денег и&nbsp;сразу обогатиться. Можно вложить очень много денег, найти
				сильную команду и&nbsp;открыть какую-нибудь суперкрутую чайную.
				Но&nbsp;важно понимать: чайная&nbsp;&mdash; долгоиграющий проект. Потому
				что чай&nbsp;&mdash; не&nbsp;тот продукт, который люди ищут сами.{" "}
				<strong>
					Чай&nbsp;&mdash; это тот продукт, к&nbsp;которому человека необходимо
					привести.
				</strong>{" "}
				Но&nbsp;человек, однажды попробовавший качественный чай, с&nbsp;трудом
				потом будет на&nbsp;ежедневной основе пить чай из&nbsp;пакетика.
			</>
		),
	},
]
