import React from "react"

import MainBannerLogo from "./assets/MainBannerLogo.png"
import MediaMainBanner from "./assets/MediaMainBanner.webp"
import MediaMainBannerMobile from "./assets/MediaMainBannerMobile.webp"
import DesktopMediaMainBanner from "./assets/DesktopMediaMainBanner.png"

import styles from "./styles.module.scss"

export const mainBanner = {
	banner: {
		mediaContent: (
			<>
				<img
					className={styles.mainBanner__desktop}
					src={MediaMainBanner}
					alt="Чайная Золотой Жук"
					loading="lazy"
				/>
				<img
					className={styles.mainBanner__tablet}
					src={MediaMainBannerMobile}
					alt="Чайная Золотой Жук"
					loading="lazy"
				/>
			</>
		),
		desktopBanner: (
			<img
				className={styles.desktopBanner}
				src={DesktopMediaMainBanner}
				alt="Чайная Золотой Жук"
				loading="lazy"
			/>
		),
		logo: (
			<img
				className={styles.logo}
				src={MainBannerLogo}
				alt="Логотип"
				loading="lazy"
			/>
		),
		quote: (
			<>
				Как бизнес «для своих» вырос до популярной чайной-кальянной и франшизы.{" "}
				<span className={styles.heading}>
					Кейс <span className={styles.quote}>«Золотой Жук»</span>
				</span>
			</>
		),
	},
	heading:
		"Как интерес к чаю перерос в фуд-проект с оборотом больше 2 млн рублей в месяц",
	textarea: (
		<p className={styles.mbParagraph}>
			Александр Золотарёв начал торговать чаем в сложный момент для себя, да и
			для всей мировой экономики — после кризиса 2008 года. Маленький бизнес
			вырос до популярной в Воронеже чайной-кальянной «Золотой Жук» и заведений
			в Курске и Донецке, работающих по франшизе. В планах Александра Золотарёва
			растить сеть. Но так было не всегда. Рассказываем о концепции, благодаря
			которой бизнес уже больше десяти лет не только держится на плаву, но и
			развивается. Делимся показателями и тем, какой чай больше всего любят
			гости и как работать с таким продуктом, чтобы не отпугнуть аудиторию
			мнимой элитарностью хорошего чая.
		</p>
	),
	// video: {
	// 	src: "https://www.youtube.com/embed/NpEaa2P7qZI",
	// 	textarea: (
	// 		<p className={styles.textarea}></p>
	// 	),
	// },
}
